import { FlagsUtils } from './flags-utils';

export default class FlagFocusing {
  constructor(form) {
    this._textureChoosingStep = form.querySelector('.js-flags-texture-step');

    if (!this._textureChoosingStep) {
      return;
    }

    this._flags = this._textureChoosingStep.querySelectorAll('.js-flag');

    this._onFocusFlag = this._onFocusFlag.bind(this);
    this._textureChoosingStep.addEventListener('click', this._onFocusFlag);

    /*
      Первичное отключение Фокуса для всех Флажков,
      кроме самого Первого
    */
    FlagsUtils._FlagFocus.disableAllExceptFirst(this._flags);
  }

  // *** Хендлер для фокусировки на выбранном флаге ***
  _onFocusFlag(evt) {
    const flagToFocus = evt.target.closest('.js-flag');

    if (!flagToFocus) {
      return;
    }

    const currentActiveFlag = FlagsUtils._ActiveFlag.get(this._flags);

    if (!currentActiveFlag) {
      FlagsUtils._ActiveFlag.set(flagToFocus);
    } else {
      FlagsUtils._ActiveFlag.off(currentActiveFlag);
      FlagsUtils._ActiveFlag.set(flagToFocus);
    }
  }
}
