/* eslint-disable arrow-parens */
/* eslint no-unused-expressions: ["error", { "allowShortCircuit": true }] */

export default class RibbonTextureSelect {
  static CNST_FIRST_ITEM = 0;

  constructor(form) {
    this._ribbonTextureStep = form.querySelector('.js-ribbon-texture-step');

    if (!this._ribbonTextureStep) {
      return;
    }

    this._ribbon = this._ribbonTextureStep.querySelector('.js-ribbon');
    this._ribbonTexturesList = this._ribbonTextureStep.querySelector('.js-ribbon-textures-list');

    this._onSelectRibbonTexture = this._onSelectRibbonTexture.bind(this);
    this._ribbonTexturesList.addEventListener('click', this._onSelectRibbonTexture);

    // *** Первичная проверка на выбранную Текстуру для Ленты ***
    this._checkRibbonTexture();
  }

  // *** Ф-ция для проверки Текстуры для Ленты ***
  _checkRibbonTexture() {
    const ribbonTextureOptions = this._ribbonTexturesList.querySelectorAll('.js-ribbon-texture');
    const ribbonTextureOptionType = Array.from(ribbonTextureOptions)
        .at(RibbonTextureSelect.CNST_FIRST_ITEM)
        .tagName.toLowerCase();

    let ribbonTextures = [];

    switch (ribbonTextureOptionType) {
      case 'label':
        for (let ribbonTextureOption of ribbonTextureOptions) {
          const ribbonTextureOptionID = ribbonTextureOption.getAttribute('for');
          ribbonTextures.push(this._ribbonTexturesList.querySelector(`input[id="${ribbonTextureOptionID}"]`));
        }
        break;

      default:
        ribbonTextures = Array.from(ribbonTextureOptions);
    }

    const selectedRibbonTexture = ribbonTextures.find(item => item.checked);

    if (selectedRibbonTexture) {
      this._colorizeRibbon(selectedRibbonTexture);
    }
  }

  // *** Ф-ция для "закрашивания" Ленты ***
  _colorizeRibbon(selectedTexture) {
    const selectedTextureType = selectedTexture.tagName.toLowerCase();
    let selectedTextureColor;

    switch (selectedTextureType) {
      case 'label':
        const selectedTextureInputID = selectedTexture.getAttribute('for');
        const selectedTextureInput = selectedTexture.closest('.js-step').querySelector(`input[id="${selectedTextureInputID}"]`);
        selectedTextureColor = selectedTextureInput.dataset.ribbonTextureColor;
        break;

      case 'button':
      default:
        selectedTextureColor = selectedTexture.dataset.ribbonTextureColor;
    }

    this._ribbon.style = `background-color: ${selectedTextureColor}`;
  }

  // *** Хенделер для "закрашивания" Ленты выбранной Текстурой ***
  _onSelectRibbonTexture(evt) {
    const ribbonTexture = evt.target.closest('.js-ribbon-texture');
    ribbonTexture && this._colorizeRibbon(ribbonTexture);
  }
}
