/* eslint-disable arrow-parens */
export default class FormStepValidation {
  static CNST_FIRST_ARRAY_ITEM = 0;
  static CNST_LAST_ARRAY_ITEM = -1;
  static CNST_EMPTY_VALUE = '';

  constructor(form) {
    this._steps = form.querySelectorAll('.js-step');
    this._lastStep = Array.from(this._steps).at(FormStepValidation.CNST_LAST_ARRAY_ITEM);
    this._actions = form.querySelector('.js-form-actions');
    this._actionPrevStep = form.querySelector('.js-form-action--prev-step');
    this._actionNextStep = this._actions.querySelector('.js-form-action--next-step');
    this._actionSubmit = this._actions.querySelector('.js-form-action--submit');

    this._FlagsStepState = {
      VALID: 'js-step--valid',
      ADDITIONAL: 'js-step--additional',
      ACTIVE_CLASS_MOD: 'flags-creation-step--is-active',
    };
  }

  // *** Ф-ция для проверки текущего Шага на Валидность ***
  checkStepValidity(currentStep) {
    const currentStepOptions = currentStep.querySelectorAll('.js-step-option');
    const firstOption = Array.from(currentStepOptions).at(FormStepValidation.CNST_FIRST_ARRAY_ITEM);
    const optionType = firstOption.tagName.toLowerCase();
    let checkedOption;

    switch (optionType) {
      case 'input':
        checkedOption = Array.from(currentStepOptions).find(item => item.checked);
        break;

      case 'button':
        const hiddenOptionValues = currentStep.querySelectorAll('input[type="hidden"]');
        checkedOption = Array.from(hiddenOptionValues).find(item => item.value !== FormStepValidation.CNST_EMPTY_VALUE);
        break;
    }

    const isStepValid = currentStep.classList.contains(this._FlagsStepState.VALID);
    const isStepOptional = currentStep.classList.contains(this._FlagsStepState.ADDITIONAL);
    const isOptionChecked = checkedOption ? true : false;

    switch (true) {
      case isStepValid:
        this._actionNextStep.disabled = false;
        break;

      case isStepOptional:
      case isOptionChecked:
        currentStep.classList.add(this._FlagsStepState.VALID);
        this._actionNextStep.disabled = false;
        break;

      default:
        this._actionNextStep.disabled = true;
    }

    if (currentStep === this._lastStep) {
      const isAllStepsValid = Array.from(this._steps).every(item => item.classList.contains(this._FlagsStepState.VALID));
      this._actionSubmit.disabled = isAllStepsValid ? false : true;
    }
  }
}
