/* eslint-disable arrow-parens */
import { FlagsUtils } from './flags-utils';

export default class FlagTextureUpdate {
  static CNST_EMPTY_VAL = '';
  static CNST_FIRST_ITEM = 0;

  constructor(form) {
    this._flagsTextureStep = form.querySelector('.js-flags-texture-step');
    this._ribbonTextureStep = form.querySelector('.js-ribbon-texture-step');

    if (!this._flagsTextureStep || !this._ribbonTextureStep) {
      return;
    }

    this._hiddenTextureInputs = this._flagsTextureStep.querySelectorAll('.js-flag-textures-hidden-value');
    this._flagTextures = this._flagsTextureStep.querySelectorAll('.js-flag-texture');
    this._finalFlags = this._ribbonTextureStep.querySelectorAll('.js-final-flag');
    this._updateButton = form.querySelector('.js-form-action--next-step');

    this._onUpdateFlagTextures = this._onUpdateFlagTextures.bind(this);
    this._updateButton.addEventListener('click', this._onUpdateFlagTextures);
  }

  // *** Ф-ция для обновления финального состояния Флажков ***
  _updateFinalFlagTextures() {
    const selectedTextureIDs = Array.from(this._hiddenTextureInputs).filter(element => element.value !== FlagTextureUpdate.CNST_EMPTY_VAL);
    let textureValCounter = 0;

    const selectedTextureHiddenValues = Array.from(this._finalFlags).map(() => {
      let selectedTextureSrc;

      if (selectedTextureIDs.length) {
        if (textureValCounter >= selectedTextureIDs.length) {
          textureValCounter = 0;
        }

        const hiddenTextureValue = selectedTextureIDs[textureValCounter].value;
        textureValCounter++;

        const selectedTexture = Array.from(this._flagTextures).find(element => element.dataset.flagTexture === hiddenTextureValue);
        selectedTextureSrc = selectedTexture.querySelector('img').getAttribute('src');
      }

      return selectedTextureSrc;
    });


    const isTextureHidValEmpty = selectedTextureHiddenValues.at(FlagTextureUpdate.CNST_FIRST_ITEM) === FlagTextureUpdate.CNST_EMPTY_VAL;
    const isTextureHidValUndef = typeof selectedTextureHiddenValues.at(FlagTextureUpdate.CNST_FIRST_ITEM) === 'undefined';


    if (isTextureHidValEmpty || isTextureHidValUndef) {
      return;
    }

    Array.from(this._finalFlags).forEach((element, index) => {
      FlagsUtils.colorizeFlag({
        currentFlag: element,
        flagTextureSrc: selectedTextureHiddenValues[index],
      });
    });
  }

  // *** Хендлер для обновления Финального состояния Текстур Флажков ***
  _onUpdateFlagTextures(evt) {
    evt.preventDefault();
    this._updateFinalFlagTextures();
  }
}
