/* eslint-disable no-new */
import Swiper from 'swiper/bundle';

export const initSwiper = () => {
  const sliders = document.querySelectorAll('.js-swiper');

  sliders.forEach((slider) => {
    new Swiper(slider, {
      grabCursor: true,
      slidesPerView: 'auto',
      spaceBetween: 8,
      freeMode: true,
      slideClass: 'flags-options__item',
      wrapperClass: 'flags-options',
      watchSlidesProgress: true,
    });
  });
};
