/* eslint-disable arrow-parens */
export default class FlagsCostCalculation {
  static CNST_DECIMAL_RADIX = 10;
  static CNST_INITIAL_VALUE = 0;

  constructor(form) {
    this._amountBlock = form.querySelector('.js-amount');

    if (!this._amountBlock) {
      return;
    }

    this._steps = form.querySelectorAll('.js-step');
    this._flagsCountStep = form.querySelector('.js-step--flags-count');
    this._additionalOptionsStep = form.querySelector('.js-step--additional');
    this._additionalOptions = this._additionalOptionsStep.querySelectorAll('.js-step-option');
    this._options = form.querySelectorAll('.js-step-option');

    this._requiredOptions = Array.from(this._options).filter((item) => {
      const isOptional = item.closest('.js-step--additional');

      if (!isOptional) {
        return item.dataset.optionCost;
      } else {
        return null;
      }
    });

    this._onSelectOption = this._onSelectOption.bind(this);
    this._steps.forEach(step => step.addEventListener('click', this._onSelectOption));

    this._init();
  }

  _init() {
    /*
      Первчиный расчёт стоимостей: при первой загрузке
      (либо — релоаде) страницы
    */
    this._calculateAdditionalOptsCost();
    this._calculateTotalCost();
  }

  // *** Ф-ция для расчёта стоимости Дополнителдьных опций ***
  _calculateAdditionalOptsCost() {
    const checkedFlagsCountOption = this._flagsCountStep.querySelector('.js-step-option:checked');

    if (!checkedFlagsCountOption) {
      return;
    }

    const flagsCount = parseInt(checkedFlagsCountOption.dataset.flagsCount, FlagsCostCalculation.CNST_DECIMAL_RADIX);

    Array.from(this._additionalOptions).forEach((element) => {
      const addOpt_amountBox = element.parentElement.querySelector('.js-step-option--additional-opt-amount');
      const addOpt_cost = parseInt(element.dataset.optionCost, FlagsCostCalculation.CNST_DECIMAL_RADIX);
      const addOpt_amount = addOpt_cost * flagsCount;

      addOpt_amountBox.textContent = addOpt_amount;
    });
  }

  // *** Ф-ция для расчёта Итоговой стоимости Гирлянды ***
  _calculateTotalCost() {
    const checkedOptionsCosts = this._requiredOptions.filter((item) => item.checked).map((option) => option.dataset.optionCost);

    let amount = checkedOptionsCosts.reduce(
        (amountVal, optionCost) => amountVal + parseInt(optionCost, FlagsCostCalculation.CNST_DECIMAL_RADIX),
        FlagsCostCalculation.CNST_INITIAL_VALUE
    );

    const checkedAdditionalOption = Array.from(this._additionalOptions).find((item) => item.checked);

    if (checkedAdditionalOption) {
      const additionalOptionAmountText = checkedAdditionalOption.parentElement.querySelector('.js-step-option--additional-opt-amount').textContent;
      const additionalOptionAmount = parseInt(additionalOptionAmountText, FlagsCostCalculation.CNST_DECIMAL_RADIX);
      amount += additionalOptionAmount;
    }

    this._amountBlock.textContent = amount;
  }

  // *** Хендлер для выбора Опций ***
  _onSelectOption(evt) {
    const optionTarget = evt.target.closest('.js-step-option');

    if (!optionTarget) {
      return;
    }

    const isFlagsCountOption = optionTarget.closest('.js-step--flags-count');

    if (isFlagsCountOption) {
      this._calculateAdditionalOptsCost();
    }

    this._calculateTotalCost();
  }
}
