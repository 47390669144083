/* eslint-disable arrow-parens */
/*
=============================================================================
---------------- Сборник утилит для настроек работы Флажков  ----------------
=============================================================================
*/

// *** Словарик CSS-модов для состояний Флажка ***
const _FlagStateMod = {
  JS_ACTIVE: 'js-flag--active',
  CSS: {
    active: 'flags__button--active',
    colored: 'flags__button--colored',
  },
};

/*
  Ф-ция для генерации WebP-адреса на основе переданного адреса картинки

  Используется для подмены srcset-атрибутов внутри <source>-элементов
  для <picture>.
*/
const getWebpSrcset = (imgAddr) => {
  const extensionStartIndex = imgAddr.lastIndexOf('.');
  const addrWithoutExt = imgAddr.slice(0, extensionStartIndex);

  return `${addrWithoutExt}.webp, ${addrWithoutExt}@2x.webp 2x`;
};


export const FlagsUtils = {
  _FlagStateMod,


  // *** Методы для работы с Активным Флажком ***
  _ActiveFlag: {
    // >- получение Активного Флажка -<
    get(flagsList) {
      return Array.from(flagsList).find((element) => element.classList.contains(_FlagStateMod.JS_ACTIVE));
    },

    // >- установка Активного Флажка -<
    set(currentFlag) {
      currentFlag.classList.add(_FlagStateMod.JS_ACTIVE, _FlagStateMod.CSS.active);
    },

    // >- отключение Активного Флажка -<
    off(currentFlag) {
      currentFlag.classList.remove(_FlagStateMod.JS_ACTIVE, _FlagStateMod.CSS.active);
    },
  },


  // *** "Раскрашивание" Флажка ***
  colorizeFlag({ currentFlag, flagTextureSrc }) {
    const currentFlagImg = currentFlag.querySelector('img');
    const currentFlagImgParent = currentFlagImg.parentElement;
    const currentFlagImgParentType = currentFlagImgParent.tagName.toLowerCase();

    currentFlagImg.setAttribute('src', flagTextureSrc);
    currentFlagImg.setAttribute('srcset', flagTextureSrc);

    if (currentFlagImgParentType === 'picture') {
      const currentFlagResponsiveConf = currentFlagImgParent.querySelector('source');

      if (currentFlagResponsiveConf) {
        const webpSrcset = getWebpSrcset(flagTextureSrc);
        currentFlagResponsiveConf.setAttribute('srcset', webpSrcset);
      }
    }

    currentFlag.classList.add(_FlagStateMod.CSS.colored);
  },


  // *** Методы для работы с Фокусом/Дефокусом ***
  _FlagFocus: {
    disableAllExceptFirst(flagsList) {
      Array.from(flagsList).forEach((element, index) => {
        if (index !== 0) {
          element.setAttribute('disabled', true);
        }
      });
    },

    enableAll(flagsList) {
      Array.from(flagsList).forEach((element) => {
        element.removeAttribute('disabled');
      });
    },

    enable(currentFlag) {
      currentFlag.removeAttribute('disabled');
    },
  },
};
