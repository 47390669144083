/*
==========================================================================================
----------------------- УТИЛИТАРНЫЙ МОДУЛЬ: Фикс vh-единиц для iOS -----------------------
==========================================================================================
*/
export const iosVhFix = () => {
  const FIXED_DECIMALS = 2;

  if (!((!!window.MSInputMethodContext && !!document.documentMode))) {
    let vh = (window.innerHeight * 0.01).toFixed(FIXED_DECIMALS);
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    window.addEventListener('resize', () => {
      vh = (window.innerHeight * 0.01).toFixed(FIXED_DECIMALS);
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
  }
};
