import { FlagsUtils } from './flags-utils';

export default class FormAction {
  static CNST_EMPTY_VALUE = '';
  static CNST_INITIAL_VALUE = 0;

  constructor() {
    this._form = document.querySelector('.js-flags-creation-form');
    this._formSteps = this._form.querySelectorAll('.js-step');
    this._hiddenValues = this._form.querySelectorAll('input[type="hidden"]');
    this._flags = this._form.querySelectorAll('.js-flag');
    this._finalFlags = this._form.querySelectorAll('.js-final-flag');
    this._ribbon = this._form.querySelector('.js-ribbon');
    this._amount = this._form.querySelector('.js-amount');
  }

  clear() {
    if (!this._form) {
      return;
    }

    this._form.reset();

    Array.from(this._formSteps).forEach((step) => step.classList.remove('js-step--valid'));
    Array.from(this._hiddenValues).forEach((hiddenValue) => (hiddenValue.value = FormAction.CNST_EMPTY_VALUE));

    const activeFlag = FlagsUtils._ActiveFlag.get(this._flags);
    const firstFlag = this._flags[FormAction.CNST_INITIAL_VALUE];

    FlagsUtils._FlagFocus.disableAllExceptFirst(this._flags);
    FlagsUtils._ActiveFlag.off(activeFlag);
    FlagsUtils._ActiveFlag.set(firstFlag);

    Array.from(this._flags).forEach((flag) => flag.classList.remove(FlagsUtils._FlagStateMod.CSS.colored));
    Array.from(this._finalFlags).forEach((flag) => flag.classList.remove(FlagsUtils._FlagStateMod.CSS.colored));

    this._ribbon.removeAttribute('style');
    this._amount.textContent = FormAction.CNST_INITIAL_VALUE;
  }
}
