/* Utils */
import { iosVhFix } from './utils/ios-vh-fix';

/* Modules */
import { initFlagsFormLogic } from './modules/init-flags-form-logic';
import { initSwiper } from './modules/init-swiper';


document.addEventListener('DOMContentLoaded', () => {
  /* Utils */
  iosVhFix();

  /* Modules */
  initFlagsFormLogic();
  initSwiper();
});
