/* eslint-disable arrow-parens */
import { FlagsUtils } from './flags-utils';

export default class FlagTextureSelect {
  static CNST_EMPTY_VAL = '';

  constructor(form) {
    this._textureChoosingStep = form.querySelector('.js-flags-texture-step');

    if (!this._textureChoosingStep) {
      return;
    }

    this._hiddenInputs = this._textureChoosingStep.querySelectorAll('.js-flag-textures-hidden-value');
    this._flags = this._textureChoosingStep.querySelectorAll('.js-flag');
    this._texturesList = this._textureChoosingStep.querySelector('.js-flag-textures-list');

    this._onSelectTexture = this._onSelectTexture.bind(this);

    this._texturesList.addEventListener('click', this._onSelectTexture);

    /*
      Отрабатывает только при первой загрузке страницы.
      Нужно, когда произошёл непредвиденный релоад страницы.

      Т.к. в хидден-инпутах значения сохраняются по дефолту и не сбрасываются
      при релоаде страницы, а Флажки, при этом, остаются пустыми, необходим механизм проверки:
      если хотя бы одно значение в хидден-инпуте будет найдено,
      то нужный Флажок автоматически "раскрасится" в нужную Текстуру.
    */
    this._checkHiddenColors();
  }

  // *** Ф-ция для автофокуса на следующем Флажке ***
  _switchFocusToNextFlag(currentFlag) {
    const currentFlagParent = currentFlag.parentElement;
    const nextFlagParent = currentFlagParent.nextElementSibling;

    if (!nextFlagParent) {
      FlagsUtils._ActiveFlag.off(currentFlag);
      return;
    }

    const nextFlag = currentFlagParent.nextElementSibling.querySelector('.js-flag');

    FlagsUtils._ActiveFlag.off(currentFlag);
    FlagsUtils._ActiveFlag.set(nextFlag);
    FlagsUtils._FlagFocus.enable(nextFlag);
  }

  /*
    Ф-ция для предварительной проверки
    на заполненность закрашенных Флажков
  */
  _checkHiddenColors() {
    const filledHiddenColorValues = Array.from(this._hiddenInputs).filter(item => item.value !== FlagTextureSelect.CNST_EMPTY_VAL);

    if (!filledHiddenColorValues.length) {
      return;
    }

    filledHiddenColorValues.forEach((element, index) => {
      const textureID = element.value;
      const desiredTextureItem = this._texturesList.querySelector(`.js-flag-texture[data-flag-texture="${textureID}"]`);
      const desiredTextureImgSrc = desiredTextureItem.querySelector('img').getAttribute('src');
      const uncoloredFlag = this._flags[index];

      FlagsUtils.colorizeFlag({
        currentFlag: uncoloredFlag,
        flagTextureSrc: desiredTextureImgSrc,
      });
    });
  }

  // *** Хендлер для выбора текстуры ***
  _onSelectTexture(evt) {
    const selectedTexture = evt.target.closest('.js-flag-texture');
    const activeFlag = FlagsUtils._ActiveFlag.get(this._flags);

    if (!selectedTexture || !activeFlag) {
      return;
    }

    const selectedTextureImgSrc = selectedTexture.querySelector('img').getAttribute('src');

    const indexOfActiveFlag = Array.from(this._flags).findIndex((element) => element === activeFlag);
    const hiddenInputForActiveFlag = Array.from(this._hiddenInputs).find((element, index) => index === indexOfActiveFlag);

    hiddenInputForActiveFlag.value = selectedTexture.dataset.flagTexture;
    FlagsUtils.colorizeFlag({
      currentFlag: activeFlag,
      flagTextureSrc: selectedTextureImgSrc,
    });

    this._switchFocusToNextFlag(activeFlag);
  }
}
