/* eslint-disable arrow-parens */

import FormStepValidation from './form-step-validation';

export default class FormStepsSwitching {
  static RE_FOR_NUMBERS = /\d/;
  static CNST_FIRST_ARR_ELEMENT = 0;
  static CNST_LAST_ARR_ELEMENT = -1;

  constructor(form) {
    this._form = form;
    this._formSteps = this._form.querySelectorAll('.js-step');
    this._prevStepSwitcher = this._form.querySelector('.js-form-action--prev-step');
    this._nextStepSwitcher = this._form.querySelector('.js-form-action--next-step');
    this._submitAction = this._form.querySelector('.js-form-action--submit');

    // *** Словарик для свойств Шага ***
    this._FlagsCreationStepProp = {
      ACTIVE_CLASS_MOD: 'flags-creation-step--is-active',
      INACTIVE_CLASS_MOD: {
        to_left: 'flags-creation-step--is-inactive-left',
        to_right: 'flags-creation-step--is-inactive-right',
      },
    };

    // *** Словарик для "направления" движения Шага ***
    this._StepSwitchingDirection = {
      TO_NEXT: 'to-next-step',
      TO_PREV: 'to-prev-step',
    };

    // --- ID для Первого и Последнего Шагов в списке ---
    this._firstStepID = Array.from(this._formSteps).at(FormStepsSwitching.CNST_FIRST_ARR_ELEMENT).dataset.flagsCreationStepId;
    this._lastStepID = Array.from(this._formSteps).at(FormStepsSwitching.CNST_LAST_ARR_ELEMENT).dataset.flagsCreationStepId;

    this._fsValidation = new FormStepValidation(this._form);

    this._onSwitchStep = this._onSwitchStep.bind(this);

    this._prevStepSwitcher.addEventListener('click', this._onSwitchStep);
    this._nextStepSwitcher.addEventListener('click', this._onSwitchStep);

    this._prevStepSwitcher.classList.add('is-hidden');
  }

  // *** Ф-ция для получения корректного ID для Шага ***
  _getCorrectStepID({ stepID, stepDirection }) {
    const stepIDNumber = parseInt(stepID.match(FormStepsSwitching.RE_FOR_NUMBERS), 10);
    let correctStepID;

    switch (stepDirection) {
      case this._StepSwitchingDirection.TO_NEXT:
        correctStepID = stepID.replace(FormStepsSwitching.RE_FOR_NUMBERS, stepIDNumber + 1);
        break;

      case this._StepSwitchingDirection.TO_PREV:
        correctStepID = stepID.replace(FormStepsSwitching.RE_FOR_NUMBERS, stepIDNumber - 1);
        break;

      default:
        correctStepID = stepID.replace(FormStepsSwitching.RE_FOR_NUMBERS, stepIDNumber + 1);
    }

    return correctStepID;
  }

  // *** Ф-ция для получения Активного Шага ***
  _getActiveStep() {
    const activeStep = Array.from(this._formSteps).find(element => element.classList.contains(this._FlagsCreationStepProp.ACTIVE_CLASS_MOD));
    const activeStepID = activeStep.dataset.flagsCreationStepId;

    return {
      STEP: activeStep,
      ID: activeStepID,
    };
  }

  // *** Хендлер для Переключения Шагов ***
  _onSwitchStep(evt) {
    evt.preventDefault();

    const isNextStepSwitcher = evt.currentTarget.classList.contains('js-form-action--next-step');
    const isPrevStepSwitcher = evt.currentTarget.classList.contains('js-form-action--prev-step');

    const currentStepProps = this._getActiveStep();
    let stepToSwitchID;
    let inactiveClassMod;

    switch (true) {
      case isNextStepSwitcher:
        stepToSwitchID = this._getCorrectStepID({
          stepID: currentStepProps.ID,
          stepDirection: this._StepSwitchingDirection.TO_NEXT,
        });
        inactiveClassMod = this._FlagsCreationStepProp.INACTIVE_CLASS_MOD.to_left;
        break;

      case isPrevStepSwitcher:
        stepToSwitchID = this._getCorrectStepID({
          stepID: currentStepProps.ID,
          stepDirection: this._StepSwitchingDirection.TO_PREV,
        });
        inactiveClassMod = this._FlagsCreationStepProp.INACTIVE_CLASS_MOD.to_right;
        break;
    }

    const stepToSwitch = this._form.querySelector(`.js-step[data-flags-creation-step-id="${stepToSwitchID}"]`);
    this._fsValidation.checkStepValidity(stepToSwitch);

    if (stepToSwitch) {
      currentStepProps.STEP.classList.remove(this._FlagsCreationStepProp.ACTIVE_CLASS_MOD);
      currentStepProps.STEP.classList.add(inactiveClassMod);
      stepToSwitch.classList.remove(this._FlagsCreationStepProp.INACTIVE_CLASS_MOD.to_left, this._FlagsCreationStepProp.INACTIVE_CLASS_MOD.to_right);
      stepToSwitch.classList.add(this._FlagsCreationStepProp.ACTIVE_CLASS_MOD);

      /*
        1) ЕСЛИ ID Шага для переключения совпадает
        .. с ID для первого Шага в списке,
        .. ТО скрыть кнопку "Назад".

        2) ЕСЛИ ID Шага для переключения совпадает
        .. с ID для последнего Шага в списке,
        .. ТО скрыть кнопку "Далее", показать кнопку "Оформление".

        default) Показать все кнопки "Назад", показать кнопку "Далее",
        .. скрыть кнопку "Оформление".
      */
      switch (stepToSwitchID) {
        case this._firstStepID:
          this._prevStepSwitcher.classList.add('is-hidden');
          break;

        case this._lastStepID:
          this._nextStepSwitcher.classList.add('is-hidden');
          this._submitAction.classList.remove('is-hidden');
          break;

        default:
          this._prevStepSwitcher.classList.remove('is-hidden');
          this._nextStepSwitcher.classList.remove('is-hidden');
          this._submitAction.classList.add('is-hidden');
      }
    }
  }
}
