/* eslint-disable arrow-parens */
export default class AdditionalOptsToggling {
  constructor() {
    this._options = document.querySelectorAll('.js-step-option');

    if (!this._options.length) {
      return;
    }

    this._toggleableOptions = Array.from(this._options)
        .filter(item => item.closest('.js-step').classList.contains('js-step--additional'));

    this._onCheckOptionState = this._onCheckOptionState.bind(this);
    this._toggleableOptions.forEach((toggleableOpt) => {
      const toggleableOptionLabel = this._getOptionProp(toggleableOpt).label();
      toggleableOptionLabel.addEventListener('click', this._onCheckOptionState);
    });
  }

  // *** Геттер элементов Опции ***
  _getOptionProp(option) {
    const optionStepParent = option.closest('.js-step');
    let optionID;

    return {
      input() {
        optionID = option.getAttribute('for');
        return optionStepParent.querySelector(`input[id="${optionID}"]`);
      },

      label() {
        optionID = option.id;
        return optionStepParent.querySelector(`label[for="${optionID}"]`);
      },
    };
  }

  // *** Ф-ция для переключения состояния Опции ***
  _toggleOptionState({ option, optState }) {
    option.checked = optState
      ? false
      : true;
  }

  // *** Хендлер для проверки :checked-состояния Опции ***
  _onCheckOptionState(evt) {
    const option = this._getOptionProp(evt.currentTarget).input();
    const optState = option.checked;

    // --- Кликер для изменения состояния ---
    // option.onclick = () => this._toggleOptionState({ option, optState });
    option.onclick = () => (option.checked = optState ? false : true);
  }
}
