/* eslint-disable no-new */
/* eslint-disable arrow-parens */

import FormStepValidation from './form-logic/form-step-validation';
import FormStepsSwitching from './form-logic/form-steps-switching';
import FormAction from './form-logic/form-action';
import FlagsCostCalculation from './form-logic/flags-cost-calculation';
import FlagFocusing from './form-logic/flag-focusing';
import FlagTextureSelect from './form-logic/flag-texture-select';
import FlagTextureUpdate from './form-logic/flag-texture-update';
import RibbonTextureSelect from './form-logic/ribbon-texture-select';
import AdditionalOptsToggling from './form-logic/additional-opts-toggling';

export const initFlagsFormLogic = () => {
  const flagsForms = document.querySelectorAll('.js-flags-creation-form');

  if (!flagsForms.length) {
    return;
  }

  class FlagsForm {
    static CNST_FIRST_ARRAY_ITEM = 0;
    static CNST_LAST_ARRAY_ITEM = -1;
    static CNST_EMPTY_VALUE = '';

    constructor(form) {
      this._form = form;

      this._steps = this._form.querySelectorAll('.js-step');
      this._lastStep = Array.from(this._steps).at(FlagsForm.CNST_LAST_ARRAY_ITEM);

      this._actions = this._form.querySelector('.js-form-actions');
      this._actionPrevStep = this._form.querySelector('.js-form-action--prev-step');
      this._actionNextStep = this._actions.querySelector('.js-form-action--next-step');
      this._actionSubmit = this._actions.querySelector('.js-form-action--submit');

      this._fsValidation = new FormStepValidation(this._form);

      this._FlagsStepState = {
        VALID: 'js-step--valid',
        ADDITIONAL: 'js-step--additional',
        ACTIVE_CLASS_MOD: 'flags-creation-step--is-active',
      };

      this._onClickStepOption = this._onClickStepOption.bind(this);

      /* --- Добавление Хендлеров --- */
      this._form.addEventListener('click', this._onClickStepOption);

      /* --- Иниты модульных фрагментов логики --- */
      this._init();
      this._initFormStepsSwitching();
      this._initFormAction();
      this._initCostCalculation();
      this._initFlagFocusing();
      this._initFlagTextureSelect();
      this._initFlagTextureUpdate();
      this._initRibbonTextureSelect();
      this._initAdditionalOptsToggling();
    }

    _init() {
      // --- Первичный прогон: для назначения Первого шага Активным ---
      const flagsStepsArray = Array.from(this._steps);
      const firstStep = flagsStepsArray.find(element => element === flagsStepsArray[FlagsForm.CNST_FIRST_ARRAY_ITEM]);
      const activeStep = flagsStepsArray.find(element => element.classList.contains(this._FlagsStepState.ACTIVE_CLASS_MOD));

      switch (true) {
        case activeStep && activeStep !== firstStep:
          activeStep.classList.remove(this._FlagsStepState.ACTIVE_CLASS_MOD);
          firstStep.classList.add(this._FlagsStepState.ACTIVE_CLASS_MOD);
          this._fsValidation.checkStepValidity(firstStep);
          break;

        case typeof activeStep === 'undefined':
        default:
          firstStep.classList.add(this._FlagsStepState.ACTIVE_CLASS_MOD);
          this._fsValidation.checkStepValidity(firstStep);
      }
    }

    _initFormStepsSwitching() {
      new FormStepsSwitching(this._form);
    }

    _initFormAction() {
      const flagsFormAction = new FormAction();
      window.flagsFormAction = flagsFormAction;
    }

    _initCostCalculation() {
      new FlagsCostCalculation(this._form);
    }

    _initFlagFocusing() {
      new FlagFocusing(this._form);
    }

    _initFlagTextureSelect() {
      new FlagTextureSelect(this._form);
    }

    _initFlagTextureUpdate() {
      new FlagTextureUpdate(this._form);
    }

    _initRibbonTextureSelect() {
      new RibbonTextureSelect(this._form);
    }

    _initAdditionalOptsToggling() {
      new AdditionalOptsToggling();
    }

    // *** Хендлер для клика по Опции Шага ***
    _onClickStepOption(evt) {
      const stepOption = evt.target.closest('.js-step-option');

      if (!stepOption) {
        return;
      }

      const currentStep = stepOption.closest('.js-step');
      currentStep.classList.add(this._FlagsStepState.VALID);
      this._fsValidation.checkStepValidity(currentStep);
    }
  }


  /*
    === Инициализация логиг работы Форм ===
  */
  flagsForms.forEach(form => new FlagsForm(form));
};
